// This should be removed after Ngin uses User Service OAuth

#{$theme-scope} {
  .ngin-login.sn-modal-overlay { background: $sn-base-color-90; }
}

// Adjust Ngin login for mobile
@media(max-width: 400px) {
  #{$theme-scope} {
    .ngin-login .sn-modal {
      max-width: 300px;
      height: auto;
      max-height: none;
      margin: 10px auto;

      // iOS zooms on fields w/ font-size
      // less than 16px, so make them bigger.
      // This should probably be added to a
      // media query in the text-field module
      // in platform styles.
      .sn-text-field {
        height: $sn-input-lg;
        font-size: $sn-font-size-lg;
      }
    }
  }
}
